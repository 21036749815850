<template>
  <v-container class="content-page-half">
    <div>
      <title-header />
      <v-card class="content mt-3 pt-7">
        <validation-observer>
          <v-form @submit.prevent="submitForm" slot-scope="{ invalid }">
            <div class="px-10">
              <h3>Informações da CRC</h3>
              <v-row>
                <v-col cols="12">
                  <field
                    v-model="formCrc.description"
                    type="genericText"
                    label="Nome"
                    name="firstName"
                    :isRequired="true"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <field
                    v-model="formCrc.cnpj"
                    type="cnpj"
                    label="CNPJ"
                    name="cnpj"
                    :isRequired="true"
                  />
                </v-col>
              </v-row>

              <h3>Endereço</h3>
              <address-form :address="formCrc.address" :isRequired="true" />

              <h3>Contato</h3>
              <v-row>
                <v-col cols="12">
                  <field
                    v-model="formCrc.email"
                    type="email"
                    label="E-mail"
                    name="email"
                    :isRequired="true"
                  />
                </v-col>
              </v-row>
              <phone-field v-model="formCrc.phones" :isRequired="true" />
              <h3>
                Gerente
                <span class="info-text black-4--text">(Opcional)</span>
              </h3>
              <div class="mt-3">
                <user-list>
                  <user-list-item
                    v-for="manager in formCrc.managers"
                    :key="manager.id"
                    :user="manager"
                    @remove="removeManager(manager)"
                  ></user-list-item>
                </user-list>
              </div>
              <v-row>
                <v-col cols="12">
                  <btn-dashed
                    :btnAction="openManagersDialog"
                    depressed
                    block
                    title="Adicionar Gerente"
                  />
                </v-col>
              </v-row>
            </div>
            <add-manager-dialog :state="managersState" @submit="saveManagers" />
            <v-divider></v-divider>

            <v-card-actions class="d-flex flex-wrap justify-space-between">
              <v-btn
                @click="$router.push({ name: 'crcs' })"
                color="#FFF"
                :block="$isMobile"
                class="mt-1 cancel-button black-3--text"
                >Cancelar</v-btn
              >
              <v-btn
                type="submit"
                color="secondary-color-1"
                :disabled="invalid"
                :block="$isMobile"
                class="ma-0 mt-1 next-step-button white--text"
                >Criar</v-btn
              >
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card>
    </div>
  </v-container>
</template>

<script>
  import { ValidationObserver } from "vee-validate";
  import { mapGetters, mapActions } from "vuex";

  import Field from "@/modules/core/views/components/Field";
  import PhoneField from "@/modules/core/views/components/PhoneField";
  import TitleHeader from "@/modules/core/views/components/TitleHeader";
  import AddressForm from "@/modules/core/views/components/AddressForm.vue";
  import AddManagerDialog from "@/modules/crc/views/components/AddManagerDialog";
  import UserList from "@/modules/account/user/views/components/UserList";
  import UserListItem from "@/modules/account/user/views/components/UserListItem";
  import BtnDashed from "@/modules/core/views/components/BtnDashed";

  import { preparePhonesToSubmit } from "@/modules/core/helpers/phoneHelpers";

  export default {
    name: "RegisterCRC",

    components: {
      TitleHeader,
      ValidationObserver,
      Field,
      PhoneField,
      AddressForm,
      AddManagerDialog,
      UserList,
      UserListItem,
      BtnDashed
    },

    data: () => ({
      teste: {},
      formCrc: {
        description: null,
        cnpj: null,
        email: null,
        phones: [{}],
        address: {
          zipCode: null,
          address: null,
          number: null,
          complement: "",
          neighborhood: null,
          city: null,
          state: {}
        },
        managers: []
      },
      managersState: {
        dialog: false,
        canRemove: false,
        managers: []
      }
    }),
    mounted() {
      this.managersState.canRemove = this.userIsAdmin;
    },
    computed: {
      ...mapGetters("user", {
        userIsAdmin: "getUserIsAdmin"
      })
    },
    methods: {
      ...mapActions("crc", ["createCRC"]),
      ...mapActions(["toggleSnackbar"]),
      openManagersDialog() {
        this.managersState.managers = [...this.formCrc.managers];
        this.managersState.dialog = true;
      },
      saveManagers(managers) {
        this.formCrc.managers = [...managers];
      },
      removeManager(manager) {
        this.formCrc.managers = this.formCrc.managers.filter(
          formManager => formManager.id !== manager.id
        );
      },
      async submitForm() {
        try {
          const payload = this.mountCrcPayload();

          const response = await this.createCRC(payload);

          this.toggleSnackbar({
            text: response?.data?.message ?? "CRC cadastrado com sucesso",
            type: "success"
          });

          this.$router.push({ name: "crcs" });
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro, tente novamente",
            type: "error"
          });
        }
      },
      mountCrcPayload() {
        return {
          ...this.formCrc,
          phones: preparePhonesToSubmit(this.formCrc.phones),
          managers: this.formCrc.managers.map(manager => manager.id)
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
  .info-text {
    font-size: 16px;
  }
</style>
