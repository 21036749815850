<template>
  <div class="d-flex container align-center justify-space-between mb-3">
    <div class="d-flex align-center">
      <user-photo
        class="justify-self-start photo"
        size="sm"
        border="thin"
        v-model="user.photo"
      />
      <span class="ml-5 text-truncate" :class="nameClasses">{{
        user.fullName
      }}</span>
    </div>
    <v-icon @click="$emit('remove')">mdi-close</v-icon>
  </div>
</template>
<script>
  import UserPhoto from "@/modules/account/user/views/components/UserPhoto";

  export default {
    props: {
      user: {
        type: Object,
        required: true
      }
    },
    components: {
      UserPhoto
    },
    computed: {
      nameClasses() {
        return { "name-mobile": this.$isMobile, name: !this.$isMobile };
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .container {
    background-color: $white-1;
  }

  .photo {
    width: 32px;
    height: 32px;
  }

  .name {
    min-width: 300px;
  }

  .name-mobile {
    max-width: 140px;
  }
</style>
